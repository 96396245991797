html,
body,
#root {
  @apply w-screen h-screen overflow-hidden;
}

.page-container {
  @apply w-full h-full overflow-y-auto;
}

.checkbox input {
  display: none;
}

/* Create a custom checkbox */
.checkbox label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 20px;
}

.checkbox label:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #3c3c3c;
  border-radius: 3px;
}

/* Style the custom checkbox when checked */
.checkbox input:checked + label:before {
  border-color: #59a719;
  background-color: #59a719;
}

/* Add a checkmark when checked */
.checkbox input:checked + label:after {
  content: '';
  position: absolute;
  left: 9px;
  top: 4px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  @apply w-full px-4 flex items-center justify-center gap-4;
  height: 88px;
  box-shadow: 0px -2px 60px rgba(0, 0, 0, 0.1);
}
