.form-control {
  @apply mb-4 relative;

  input {
    @apply rounded text-paragraph-1 text-bnw-black px-2;
    -webkit-user-select: text;
  }

  label:has(+ input[required])::after {
    @apply text-alert-negative;
    content: '*';
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'] {
    @apply border border-neutral-input w-full h-11;
  }

  input[type='date'],
  input[type='time'],
  input[type='datetime-local'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white;
    text-align: left;
    padding-left: 10px;
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }

  textarea {
    @apply border border-neutral-input w-full p-2 rounded-sm;
    height: 60px;
    resize: none;
    -webkit-user-select: text;
  }
}
