@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'src/styles/index';

* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: 'Android App 100';
  src: url('../public/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Android App 300';
  src: url('../public/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Android App 500';
  src: url('../public/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Android App 700';
  src: url('../public/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Android App 900';
  src: url('../public/fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: 'IOS App 100';
  src: url('../public/fonts/SF-Light.woff2');
}

@font-face {
  font-family: 'IOS App 300';
  src: url('../public/fonts/SF-Regular.woff2');
}

@font-face {
  font-family: 'IOS App 500';
  src: url('../public/fonts/SF-Medium.woff2');
}

@font-face {
  font-family: 'IOS App 700';
  src: url('../public/fonts/SF-Bold.woff2');
}

@font-face {
  font-family: 'IOS App 900';
  src: url('../public/fonts/SF-Black.woff2');
}

@font-face {
  font-family: 'icon-font';
  src: url('../public/fonts/icon-font.eot');
  src: url('../public/fonts/icon-font.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/icon-font.woff') format('woff'),
    url('../public/fonts/icon-font.ttf') format('truetype'),
    url('../public/fonts/icon-font.svg#untitled-font-7') format('svg');
  font-weight: normal;
  font-style: normal;
}
