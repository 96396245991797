// Font
$typography-headings: (1, 64px, 'App 500'), (2, 48px, 'App 300'),
  (3, 32px, 'App 300'), (4, 24px, 'App 300'), (5, 20px, 'App 500'),
  (6, 18px, 'App 500');
$typography-subtitles: (1, 16px, 'App 500'), (2, 14px, 'App 500');
$typography-paragraph: (1, 16px, 'App 300'), (2, 14px, 'App 300');
$typography-caption: (
  size: 12px,
  weight: 'App 300',
);
$typography-overline: (
  size: 12px,
  weight: 'App 300',
);
$typography-label: (
  size: 12px,
  weight: 'App 700',
);
