[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'icon-font', serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancel:before {
  content: '\e911';
}
.icon-close:before {
  content: '\e919';
}
.icon-expand-less:before {
  content: '\e906';
}
.icon-expand-more:before {
  content: '\e904';
}
.icon-help-outline:before {
  content: '\e915';
}
.icon-none:before {
  content: '\e957';
}
.icon-toilet:before {
  content: '\e964';
}
.icon-liner:before {
  content: '\e954';
}
.icon-bed-towel:before {
  content: '\e921';
}
.icon-incontinence-pad:before {
  content: '\e900';
}
.icon-incontinence-brief:before {
  content: '\e94f';
}
.icon-menstrual-pad:before {
  content: '\e900';
}
.icon-pad:before {
  content: '\e900';
}
.icon-arrow-back:before {
  content: '\e903';
}
.icon-arrow-right:before {
  content: '\e905';
}
.icon-arrow-down:before {
  content: '\e90e';
}
.icon-arrow-up:before {
  content: '\e90d';
}
.icon-arrow-upward:before {
  content: '\e90d';
}
.icon-arrow-downward:before {
  content: '\e90e';
}
.icon-arrow-right-alt:before {
  content: '\e912';
}
.icon-share:before {
  content: '\e901';
}
.icon-clothing:before {
  content: '\e914';
}
.icon-add:before {
  content: '\e91b';
}
.icon-more-vertical:before {
  content: '\e902';
}
.icon-download:before {
  content: '\e91e';
}
.icon-delete:before {
  content: '\e917';
}
.icon-verified:before {
  content: '\e91a';
}
