@use 'sass:map';
@import 'variables';

.android {
  @each $name, $size, $weight in $typography-headings {
    .text-heading-#{$name} {
      font-size: $size;
      font-family: 'Android #{$weight}';
    }
  }

  @each $name, $size, $weight in $typography-subtitles {
    .text-subtitle-#{$name} {
      font-size: $size;
      font-family: 'Android #{$weight}';
    }
  }

  @each $name, $size, $weight in $typography-paragraph {
    .text-paragraph-#{$name} {
      font-size: $size;
      font-family: 'Android #{$weight}';
    }
  }

  .text-caption {
    font-size: map.get($typography-caption, 'size');
    font-family: 'Android #{map.get($typography-caption, 'weight')}';
  }

  .text-overline {
    text-transform: uppercase;
    font-size: map.get($typography-overline, 'size');
    font-family: 'Android #{map.get($typography-overline, 'weight')}';
  }

  .text-label {
    font-size: map.get($typography-label, 'size');
    font-family: 'Android #{map.get($typography-label, 'weight')}';
  }
}

.ios {
  @each $name, $size, $weight in $typography-headings {
    .text-heading-#{$name} {
      font-size: $size;
      font-family: 'IOS #{$weight}';
    }
  }

  @each $name, $size, $weight in $typography-subtitles {
    .text-subtitle-#{$name} {
      font-size: $size;
      font-family: 'IOS #{$weight}';
    }
  }

  @each $name, $size, $weight in $typography-paragraph {
    .text-paragraph-#{$name} {
      font-size: $size;
      font-family: 'IOS #{$weight}';
    }
  }

  .text-caption {
    font-size: map.get($typography-caption, 'size');
    font-family: 'IOS #{map.get($typography-caption, 'weight')}';
  }

  .text-overline {
    text-transform: uppercase;
    font-size: map.get($typography-overline, 'size');
    font-family: 'IOS #{map.get($typography-overline, 'weight')}';
  }

  .text-label {
    font-size: map.get($typography-label, 'size');
    font-family: 'IOS #{map.get($typography-label, 'weight')}';
  }
}
