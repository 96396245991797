.btn {
  @apply border-2 border-primary rounded w-full py-3 px-4 cursor-pointer;
  @extend .text-heading-6;

  &-primary {
    @extend .btn;
    @apply bg-primary text-bnw-white;

    &:hover {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    &:active {
      @apply bg-primary-dark;
    }

    &:disabled,
    &:disabled:hover {
      @extend .btn-disabled;
    }
  }

  &-secondary {
    @extend .btn;
    @apply bg-bnw-white text-primary;

    &:hover {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    &:active {
      @apply bg-primary text-bnw-white;
    }

    &:disabled,
    &:disabled:hover {
      @extend .btn-disabled;
    }
  }
}

.btn-disabled {
  @apply bg-neutral-disabled text-neutral-inactive cursor-not-allowed shadow-none border-neutral-disabled;
}
